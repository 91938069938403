/**
 * Development environment settings
 */
export const environment = {
  production: false,
  api: 'https://dev.e-waiter.pl/api/',
  apiWithoutHttps: 'dev.e-waiter.pl/api/',
  https: 'https://',
  firebaseConfig: {
    apiKey: 'AIzaSyBuAe8wFecdaRFH4bJox7ZkboPNLRfcBxM',
    authDomain: 'ewaiter-a25bf.firebaseapp.com',
    databaseURL: 'https://ewaiter-a25bf.firebaseio.com',
    projectId: 'ewaiter-a25bf',
    storageBucket: 'ewaiter-a25bf.appspot.com',
    messagingSenderId: '556392585583',
    appId: '1:556392585583:web:4c64a74c9691a1ba58709a',
    measurementId: 'G-8RKVWZ774K',
  },
};
